<template>
  <div class="g-field" :class="{ ...variant, error: hasError }">
    <div
      v-if="$slots.messageBefore || (hasMessage && messagePosition === 'before')"
      class="message before"
      :class="{ ...variant, error: hasError }"
    >
      <slot name="messageBefore">
        {{ message || errorMessage }}
      </slot>
    </div>
    <slot name="label">
      <span v-if="label" class="label" :class="labelClass || defaultClass('labelClass')">
        {{ label }}
      </span>
    </slot>
    <slot v-if="helpTextPosition === 'before'" name="helpText">
      <div class="help-text" :class="helpTextClass">{{ helpText }}</div>
    </slot>
    <slot v-bind="{ schema: { ...$attrs, ...$props } }">
      <component
        :is="computedComponent"
        v-bind="{
          ...$attrs,
          ...inputOptions,
          type: computedComponent === 'g-input' ? type : undefined,
        }"
        v-on="$listeners"
      >
        <slot v-for="(_, name) in $slots" :name="name" />
        <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
          <slot :name="name" v-bind="slotData" />
        </template>
      </component>
    </slot>
    <div
      v-if="$slots.messageAfter || (hasMessage && messagePosition === 'after')"
      class="message after"
      :class="{ ...variant, error: hasError }"
    >
      <slot name="messageAfter">
        {{ message || errorMessage }}
      </slot>
    </div>
    <slot v-if="helpTextPosition === 'after'" name="helpText">
      <div class="help-text" :class="helpTextClass">{{ helpText }}</div>
    </slot>
  </div>
</template>

<script>
// TODO see about adding inputs directly to this component

/**
 * @version 1.0.0
 */

import baseComponentMixin from '@/components/local_modules/utils/baseComponentMixin';

export default {
  name: 'g-field',
  mixins: [baseComponentMixin],
  components: {
    GInput: () => import('@/components/GInput.vue'),
    GCheckbox: () => import('@/components/GCheckbox.vue'),
  },
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: null,
    },
    labelClass: {
      type: [String, Array],
      default: null,
    },
    helpText: {
      type: String,
      default: null,
    },
    helpTextPosition: {
      type: String,
      default: 'after',
    },
    helpTextClass: {
      type: [String, Array],
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    inputOptions: {
      type: Object,
      default: null,
    },
    inputType: {
      type: String,
      default: null,
    },
    messagePosition: {
      type: String,
      default: 'after',
      validator(value) {
        return value === 'before' || value === 'after';
      },
    },
  },
  // Playing with Provide/Inject. Kind of goofy but this is how you make reactive in Vue 2. Better ways in Vue 3
  // I got this answer from here: https://stackoverflow.com/questions/65718651/how-do-i-make-vue-2-provide-inject-api-reactive
  // MDN says Object.defineProperty has good support https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/defineProperty#browser_compatibility
  // Mixins perhaps would be better? Although then I think I would have to provide an additional file to include I think
  provide() {
    return {
      fieldError: Object.defineProperty({}, 'value', {
        enumerable: true,
        get: () => this.hasError,
      }),
    };
  },
  computed: {
    computedComponent() {
      if (this.type === 'dropdown') return 'g-dropdown';
      if (this.type === 'checkbox') return 'g-checkbox';
      if (this.type === 'switch') return 'g-switch';
      if (this.type === 'radio') return 'g-radio';
      return 'g-input';
    },
    hasError() {
      return this.error || !!this.errorMessage;
    },
    hasMessage() {
      return this.message || !!this.errorMessage;
    },
  },
};
</script>

<style lang="scss" scoped>
.g-field {
  display: block;
  width: 100%;
}
</style>
