<template>
  <div class="login">
    <div v-if="emailSent" class="email-sent">
      <span class="email-text"> Password reset email has been sent to {{ email }} </span>
      <XIcon class="x-icon" @click="hidePopup" />
    </div>
    <div v-if="confEmail" class="email-sent">
      <span class="email-text">Account verification email has been sent to {{ email }}</span>
      <XIcon class="x-icon" @click="hidePopup" />
    </div>
    <div class="logo-container">
      <DigitalPassport class="digital-logo" />
    </div>
    <h1>Welcome Back!</h1>
    <div v-if="!unverified">
      <form @submit.prevent="signIn" class="input-holder">
        <label class="form-header"
          >Email:
          <VWFormField
            v-model="email"
            class="form-field"
            :class="{ valid: email && !emailError }"
            :error="isError && !email"
            :errorMessage="emailError"
            placeholder="email@example.com"
            type="email"
        /></label>
        <label class="form-header"
          >Password:
          <VWFormField
            v-model="password"
            class="form-field"
            :class="{ valid: password }"
            :error="isError && !password"
            :errorMessage="passError"
            placeholder="Password"
            type="password"
        /></label>
        <PassportButton type="submit" label="sign in" class="login-button" />
      </form>
      <div class="button-container">
        <PassportButton label="Forgot Password?" variant="text" @click="forgotPassword" class="forgot-button" />
        <PassportButton label="No account yet? Sign up here" variant="text" to="/register" class="sign-up" />
      </div>
    </div>
    <div v-if="unverified" class="confirmation">
      <span class="confirmation-text">
        It looks like you still haven’t verified your email address with us. You must verify your email before signing
        in.
      </span>
      <PassportButton label="Resend confirmation email" variant="text" @click="resendEmail" class="log-in" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ApiService } from '@/services/api.service';
import VWFormField from '@/components/VWFormField.vue';
import DigitalPassport from '@/assets/images/logo_digital_passport.svg';
import PassportButton from '@/components/PassportButton.vue';
import XIcon from '@/assets/icons/x_icon.svg';

export default {
  name: 'Login',
  components: {
    VWFormField,
    DigitalPassport,
    PassportButton,
    XIcon,
  },
  data() {
    return {
      email: '',
      password: '',
      isError: false,
      emailError: '',
      passError: '',
      forgotPass: false,
      emailSent: false,
      unverified: false,
      confEmail: false,
    };
  },
  mounted() {},
  methods: {
    ...mapActions(['parseJwt']),

    async resendEmail() {
      const profile = {
        email: this.email,
      };
      await ApiService.post('/user/resend-verification', profile);
      this.unverified = false;
      this.confEmail = true;
      setTimeout(() => {
        this.confEmail = false;
      }, 5000);
    },
    async signIn(event) {
      const profile = {
        email: event.srcElement[0].value,
        password: event.srcElement[1].value,
      };
      this.isError = true;

      if (profile.email && profile.password) {
        try {
          this.error = false;
          const { token } = (await ApiService.post('/user/auth', profile)).data;
          this.parseJwt(token);
          this.unverified = false;
          if (this.$route.query.next) this.$router.push(this.$route.query.next);
          else this.$router.push('/home');
        } catch (error) {
          if (error.response.status === 422) {
            this.emailError = 'Please enter a valid email address';
          } else if (error.response.data.error === 'unauthorized') {
            this.passError = 'Invalid Login Credentials';
            this.emailError = 'Invalid Login Credentials';
          } else if (error.response.data.error === 'user not verified') {
            this.unverified = true;
          }
        }
      }
    },
    async forgotPassword() {
      if (!this.email) {
        this.emailError = 'This field is required';
      } else {
        await ApiService.post('/user/forgot', { email: this.email }).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            this.emailSent = true;
            this.emailError = '';
          }
        });
      }
    },
    hidePopup() {
      this.emailSent = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  font-size: 1.2rem;
  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5em;
  }
  .main-logo {
    width: 3.875em;
    height: 1.875em;
  }
  .digital-logo {
    width: 14.5em;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .login-button {
    width: 15.625em;
    height: 3em;
    margin-top: 2em;
    margin-right: auto;
    margin-left: auto;
    font-family: $head-font-stack;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
  }
  .forgot-button {
    margin-top: 1em;
  }
  .sign-up {
    margin-top: 0.5em;
  }
  .input-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .form-header {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
    font-size: 12px;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    @include bp-sm-phone-landscape {
      margin-top: 1em;
    }
  }
  .form-field {
    width: 257px;
    &.valid {
      background-image: url(../assets/icons/checkmark.svg?external);
      background-repeat: no-repeat;
      background-position: right;
      background-position-x: 95%;
      background-size: 15px;
    }
    @include bp-sm-phone-landscape {
      width: 500px;
    }
  }

  ::v-deep .passport-button.text {
    font-size: 14px;
    text-decoration: underline;
  }
  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.5em;
  }
  .email-sent {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 94px;
    background-color: $primary-color;
  }
  .email-text {
    margin-left: 1em;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
  }
  .x-icon {
    display: flex;
    width: 20px;
    margin-right: 1em;
  }
  .confirmation {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin-top: 1em;
    .confirmation-text {
      font-size: 18px;
      color: $red;
      text-align: left;
      @include bp-md-tablet {
        text-align: center;
      }
    }
  }
  .log-in {
    margin-top: 1em;
  }
}
</style>
