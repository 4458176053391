<template>
  <component :is="'GField'" v-bind="{ ...$attrs, ...$props }" v-on="$listeners" class="vw-form-field">
    <slot v-for="(_, name) in $slots" :name="name" />
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import GField from '@/components/GField.vue';

export default {
  name: 'VWFormField',
  inheritAttrs: false,
  extends: GField,
  components: {
    GField,
  },
};
</script>

<style lang="scss" scoped>
.vw-form-field {
  display: block;
  width: 100%;

  &.error {
    ::v-deep {
      .label {
        color: red;
      }
      input {
        background: #ff3b6133 0% 0% no-repeat padding-box;
        border: 1px solid red;
      }
    }
  }

  ::v-deep {
    .message {
      font-size: 12px;
      color: black;

      &.error {
        color: red;
        text-transform: initial;
      }

      &.before {
        margin-bottom: 5px;
      }
    }
  }
}
</style>
